$colors-to-export: (
  ratingstarfilled: $rating-star-filled,
  ratingstarempty: $rating-star-empty,
);

:export {
  @each $key, $value in $colors-to-export {
    #{unquote($key)}: $value;
  }
}
