@import '~breakpoint-sass';

$main-font-family: 'Golos Text', -apple-system, blinkmacsystemfont, segoe ui, roboto, oxygen,
  ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;
$font-variant-numeric: initial;

// COLORS

$layout-bg: #fff;
$black: #161616;
$white: #fff;
$white-hover: #dfe4fd;
$border: #dfe4fd;
$primary: #4c16e7;
$primary-hover: #350da9;
$primary-disabled: #9789c1;
$secondary: #9a2093;
$secondary-hover: #560351;
$secondary-text: #fff;
$tertiary: #c7000c;
$tertiary-hover: #9c030c;
$tertiary-text: #fff;
$live: #4c16e7;
$live-hover: #350da9;
$scroll-up-button-default: rgba($black, 0.6);
$scroll-up-button-hover: rgba($black, 1);
$rating-star-filled: #ffd027;
$rating-star-empty: rgba($white, 0.2);
$social-media-icon-color: #fff;
$social-media-icon-bg: #4c16e7;
$subscribe-form-bg: #fff;
$subscribe-form-border: #dfe4fd;
$error: #f82a36;
$error-border: $error;
$content-widget-bg: #ffe2e2;
$placeholder-bg: #ffe2e2;
$input-border-focus: #4c16e7;
$notification-default-bg: #f8fcfe;
$notification-success-bg: #f1f3fe;
$notification-warning-bg: #fefdf6;
$notification-error-bg: #fff6f6;
$pushkin-pink: #ff0090;
$pushkin-bar: $primary;
$pushkin-card-bg: #fff;
$pushkin-card-fill: rgba($black, 0.6);
$header-button-search-bg-default: #fff;
$header-button-search-bg-hover: #4c16e7;
$header-button-search-icon-default: #4c16e7;
$header-button-search-icon-hover: #fff;
$header-button-profile-bg-default: #fff;
$header-button-profile-bg-hover: #4c16e7;
$header-button-profile-icon-default: #4c16e7;
$header-button-profile-icon-hover: #fff;
$header-block-bg: #fec0c0;
$header-block-text: #161616;
$header-block-info-button: rgba($black, 0.6);
$header-block-hint-bg: rgba($white, 0.4);
$header-block-arrows-adaptive: #161616;
$button-bg-default: #f82a36;
$button-bg-hover: #f82a36;
$button-bg-disabled: #dd888d;
$button-text-action-default: #fff;
$button-text-price-default: rgb(255 255 255 / 60%);
$button-icon-default: rgb(255 255 255 / 60%);
$button-divider-default: rgb(255 255 255 / 30%);
$button-label-bg: rgb(255 255 255 / 10%);
$button-label-icon: #fff;
$button-vk-bg-default: #07f;
$button-vk-bg-hover: #0060cd;
$card-color-bg-default: #4c16e7;
$card-color-bg-hover: #350da9;
$card-color-text-main: #fff;
$card-color-text-secondary: rgb(255 255 255 / 60%);
$card-color-badge-bg: rgba($primary, 0.7);
$card-main-border: #dfe4fd;
$card-main-bg-default: #fff;
$card-main-bg-hover: #dfe4fd;
$overview-card-border: rgba($white, 0);
$overview-card-bg-default: #fec0c0;
$overview-card-bg-hover: #ffa4a4;
$overview-card-text: #161616;
$overview-card-badge-bg: rgba($black, 0.05);
$overview-button-text-default: $black;
$overview-button-bg-default: #fec0c0;
$overview-button-hover: #ffa4a4;
$hotel-card-border: #dfe4fd;
$hotel-card-bg-default: #fff;
$hotel-card-bg-hover: #dfe4fd;
$tickets-bundle-card-border: #dfe4fd;
$tickets-bundle-card-bg-default: #fff;
$tickets-bundle-card-bg-hover: #dfe4fd;
$stream-card-border: #dfe4fd;
$stream-card-bg-default: #fff;
$stream-card-bg-hover: #dfe4fd;
$region-videos-card-border: #dfe4fd;
$region-videos-card-bg-default: #fff;
$region-videos-card-bg-hover: #dfe4fd;
$footer-bg: #560351;
$footer-text-main: #fff;
$footer-text-secondary: rgba($white, 0.6);
$footer-link-text-default: #fff;
$footer-link-text-hover: rgba($white, 0.7);
$footer-border: rgba($white, 0.5);
$header-transparent-item-default: $white;
$header-transparent-item-hover: $primary;

// HEIGHT CONSTANTS
$event-pay-button-height: 70px;
$event-video-xl: 500px;
$event-video-md: 335px;

// BREAKPOINTS:
// XL = desktop, MD = tablet, SM = mobile
$xl: 1200px;
$md: 1199px 768px;
$sm: 767px 0;
$md-and-sm: 1199px 0;
$xl-and-md: 768px;

// WIDTH CONSTANTS
$event-pay-button-width: 335px;
$event-width-wrapper: 1000px;
$event-width-content: 800px;
$max-width-image: 600px;

// OTHER CONSTANTS
$modal-z-index: 101;
$content-bold-weight: 600;
$card-bg-transition: background-color 0.3s ease;
$timetable-date-padding-top: 6px;

@mixin gap($space) {

  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin scrollbar() {

  &::-webkit-scrollbar-track {
    background-color: $black-5;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 0;
  }

  scrollbar-color: $primary $black-5;
  scrollbar-width: 10px;
}
