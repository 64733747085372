$white-5: rgba($white, 0.05);
$white-10: rgba($white, 0.1);
$white-20: rgba($white, 0.2);
$white-30: rgba($white, 0.3);
$white-40: rgba($white, 0.4);
$white-50: rgba($white, 0.5);
$white-60: rgba($white, 0.6);
$white-70: rgba($white, 0.7);
$white-80: rgba($white, 0.8);
$white-90: rgba($white, 0.8);
$black-5: rgba($black, 0.05);
$black-10: rgba($black, 0.1);
$black-20: rgba($black, 0.2);
$black-30: rgba($black, 0.3);
$black-40: rgba($black, 0.4);
$black-50: rgba($black, 0.5);
$black-60: rgba($black, 0.6);
$black-70: rgba($black, 0.7);
$black-80: rgba($black, 0.8);
$black-90: rgba($black, 0.9);
