.rc-slider {
  position: relative;
  padding: 5px 0 7px;
  width: 100%;
  touch-action: none;

  &-rail {
    position: absolute;
    width: 100%;
    background-color: $black-5;
    height: 3px;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 3px;
    background-color: $primary;
  }

  &-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin-top: -5px;
    border-radius: 50%;
    background-color: $primary;
    touch-action: pan-x;

    &:focus {
      outline: none;
    }

    &-click-focused:focus {
      box-shadow: unset;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 3px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;

    &-reverse {
      margin-right: -4px;
    }
  }

  &-disabled {

    .rc-slider-handle,
    .rc-slider-dot {
      box-shadow: none;
      background-color: $border;
      cursor: not-allowed;
    }

    .rc-slider-mark-text,
    .rc-slider-dot {
      cursor: not-allowed !important;
    }

    .rc-slider-track {
      background-color: transparent;
    }
  }
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .rc-slider {

    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      touch-action: pan-y;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;

      &:first-child {
        margin-bottom: -4px;
      }

      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: 0;
  }

  &-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;

    @include t6;

    color: $black;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
  }
}
