
      @import "regions/kursk/styles/themes/muza/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/muza/fonts.scss";
      @import "regions/kursk/styles/themes/muza/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import '~breakpoint-sass';
@import '../../common/style/reset';
@import 'common_vars';
@import 'export_vars.module';
@import 'components/Range';
@import '~bvi/dist/css/bvi.min.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $main-font-family;
  font-variant-numeric: $font-variant-numeric;
  width: 100%;
  height: 100%;
  color: $black;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s linear;
  color: $primary;
}

a.withLine {

  &:hover {
    text-decoration: underline;
  }
}

input,
button {
  font-family: $main-font-family;
  font-variant-numeric: $font-variant-numeric;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

:global {

  .body-overlay {
    overflow: hidden;
  }

  .no-scroll {
    overflow: hidden;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.bvi-copyright {
  display: none !important;
  position: fixed;
  top: -9999px;
}

.fslightbox-slide-btn-next-container {
  display: none !important;
  padding-right: 20px !important;

  @include breakpoint($xl) {
    display: initial !important;
  }
}

.fslightbox-slide-btn-previous-container {
  display: none !important;
  padding-left: 20px !important;

  @include breakpoint($xl) {
    display: initial !important;
  }
}

.fslightbox-slide-btn {
  width: 50px !important;
  height: 50px !important;
  background: $white !important;
}

.fslightbox-svg-path {
  fill: $black !important;
}

.fslightbox-toolbar {
  background: none !important;
}

.fslightbox-toolbar-button {

  svg {
    width: 18px;
    height: 18px;
  }

  .fslightbox-svg-path {
    fill: $white !important;
  }
}

.fslightbox-slide-number-container {
  background: $white !important;
  margin-left: 20px !important;
  margin-top: 20px !important;
  height: 24px !important;

  .fslightbox-flex-centered {
    color: $black !important;

    .fslightbox-slash {
      background: $black !important;
    }
  }
}
